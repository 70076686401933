<template>
	<div class="content">
		<div class="liubai">
			<Icon type="ios-search" />筛选查询
		</div>
		<div class="search">
			<div class="input">
				<div class='text'>请输入：</div>
				<Input v-model="value14" placeholder="请输入商品名称" clearable style="width: 200px" />
			</div>
			<div class="input">
				<div class='text'></div>
				<Form :model="formItem" :label-width="80">
					<FormItem label="商品分类:">
						<Select v-model="formItem.select">
							<Option :value="item.storeid" v-for='item,index in selects' :key='index'>{{item.s_name}}</Option>
						</Select>
					</FormItem>
				</Form>
			</div>
			<div class="input">
				<div class='text'></div>
				<Form :model="formItem" :label-width="80">
					<FormItem label="商品品牌:">
						<Select v-model="formItem.select2">
							<Option :value="item.storeid" v-for='item,index in selects2' :key='index'>{{item.s_name}}</Option>
						</Select>
					</FormItem>
				</Form>
			</div>
			<div class="input">
				<Button type="success" @click='search()'>搜索</Button>
			</div>
		</div>
	</div>

</template>

<script>
	export default {
		name: 'search',
		data() {
			return {
				value14: '',
				formItem: {
					select: '',
					select2: ''
				},
				selects: null,
				selects2: null
			}
		},
		created() {
			let token = this.$storage.getLocal('token');
			this.$request.selGoodsStoreName({token}).then(res=>{
				if(res.code == 0){ 
					this.selects = res.data;
				}
			})
		},
		methods: {
			search(){
				this.$emit('search',{text:this.value14,select:this.formItem.select})
			}
		}
	}
</script>

<style scoped="scoped">
	.search {
		padding-left: 200px;
		display: flex;
		/* justify-content: center; */
		text-align: left;
	}

	.input {
		/* width: 80%; */
		/* margin: 0 auto; */
		display: flex;
		justify-content: space-between;
		line-height: 30px;
		margin-left: 50px;
		padding: 10px 0 10px 0;
	}

	.liubai {
		width: 100%;
		height: 40px;
		background-color: #F3F3F3;
		font-size: 15px;
		text-align: left;
		padding-left: 220px;
		line-height: 40px;
	}
</style>
