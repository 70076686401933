<template>
	<div class="recycle">
		<frame :top1="top1" :top2="top2">
			<search @search='search'></search>
			<lists :titles='titles' :contentC='datas' @add='refresh()' @del='refresh()'></lists>
			<page :num='num'></page>
		</frame>
	</div>
</template>

<script>
	import lists from './componRecycle/RecycleList.vue'
	import search from './componRecycle/Search.vue'
	import frame from '../public/Frame.vue';
	import page from './componList/Page.vue';
	export default {
		name: 'recycle',
		data() {
			return {
				top1: '4-7',
				top2: ['4'],
				titles: ['ID', '商品图片', '商品名称', '商品分类', '价格', '货号', '操作'],
				datas: null,
				num: 100,
			}
		},
		components: {
			frame,
			lists,
			search,
			page
		},
		created() {
			let token = this.$storage.getLocal('token');
			let type = this.$storage.getLocal('type');
			this.$request.selGoodsRecycleData({token,type}).then(res=>{
				if(res.code == 0){
					this.datas = res.data
				}
			})
		},
		methods:{
			refresh(){
				let token = this.$storage.getLocal('token');
				let type = this.$storage.getLocal('type');
				this.$request.selGoodsRecycleData({token,type}).then(res=>{
					if(res.code == 0){
						this.datas = res.data
					}
				})
			},
			search(item) {
				let token = this.$storage.getLocal('token');
				this.$request.goodsQueryList({
					token,
					gname: item.text,
					storeid: item.select,
					page: 1,
					limit: 10,
					status: 1
				}).then(res => {
					if (res.code == 0) {
						this.datas = res.data.data
						this.num = res.data.cunt
					}
				})
			}
		}
	}
</script>

<style scoped="scoped">
</style>
