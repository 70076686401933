<template>
	<div class="list">
		<el-dialog title="详情" :visible.sync="dialogVisible" width="30%">
			<span>这是一段信息</span>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			</span>
		</el-dialog>
		<Card style='margin-left: 200px'>
			<div style="height: 600px;">
				<div class="titles">商品列表</div>
				<div class="title">
					<div class="title">
						<div class="listcont">
							数据列表
						</div>
						<div class="add">
						</div>
					</div>
				</div>
				<div class="content">
					<div id="contents">
						<div class="cont">
							<table>
								<tr>
									<td class='titNa' v-for="item,index in titles" :key='index'>{{item}}</td>
								</tr>
								<tr v-for="item2,index2 in contentC" :key='index2'>
									<td>{{item2.gid}}</td>
									<td class="img">
										<img v-if="item2.gimg!=null" :src="uploadingUrl1 + item2.gimg" >
									</td>
									<td>{{item2.gname}}</td>
									<td>{{item2.s_name}}</td>
									<td>{{item2.selling_price}}</td>
									<td>{{item2.gcode}}</td>
									<td class="oper">
										<div class="com" @click="start(item2.gid)">还原</div>
										<div class="del" @click="del(item2.gid)">删除</div>
									</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
		</Card>
	</div>
</template>

<script>
	export default {
		name: 'contents',
		props: {
			contentC: {
				type: Array,
			},
			titles: {
				type: Array,
			},
			flag: {
				type: Number,
			}
		},
		data() {
			return {
				social: [],
				dialogVisible: false,
				uploadingUrl1: '',
				modal1: false,
				formValidate: {
					name: '',
					city: ''
				},
				ruleValidate: {
					name: [{
						required: true,
						message: '请输入单位代码',
						trigger: 'blur'
					}],
					city: [{
						required: true,
						message: '请输入单位名称',
						trigger: 'blur'
					}]
				},
				typeFlag: null,
				list:null,
				id: false
			}
		},
		created() {
			this.uploadingUrl1 = this.$request.imgUrl2()+'uploads/';
			let token = this.$storage.getLocal('token');
			this.typeFlag = this.$storage.getLocal('type');
			this.uploadingUrl2 = this.$request.imgUrl() + 'PHPExcel/fileUpload';
		},
		components: {

		},
		methods: {
			addType() {
				this.modal1 = true
			},
			start(id) {
				let token = this.$storage.getLocal('token');
				this.$request.upGoodsRecycleData({
					token,
					id,
					status: 1,
				}).then(res => {
					if (res.code == 0) {
						this.$notify({
							title: '成功',
							message: '恢复成功',
							type: 'success'
						});
						this.$emit("del", 'ok');
					} else {
						this.$notify({
							title: '警告',
							message: res.msg,
							type: 'warning'
						})
					}
				})
			},
			del(id) { //删除
				this.$confirm('此操作将永久删除该商品,不可恢复,是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then((res) => {
					let token = this.$storage.getLocal('token');
					this.$request.upGoodsRecycleData({
						id,
						token,
						status:2
					}).then(res => {
						if (res.code == 0) {
							this.$emit("del", 'ok');
							this.$notify({
								title: '成功',
								message: '删除成功',
								type: 'success'
							});
						} else {
							this.$emit("del", 'ok');
							this.$notify({
								title: '失败',
								message: res.msg,
								type: 'warning'
							});
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			examine(id) { //查看
				this.dialogVisible = true
			},
			log(id) { //日志
				console.log(id)
			},
			compile(id,name,img) { //编辑
				this.modal1 = true;
				this.formValidate.name = name;
				this.imageUrl = this.$request.imgUrl2()+'uploads/'+img;
				this.logo = img;
				this.id = id;
			},
			cancel() {
				this.$Message.info('取消添加');
			},
			ok() {
				let token = this.$storage.getLocal('token');
				let id = this.$storage.getLocal('type')
				if(this.id){//编辑
					this.$refs['formValidate'].validate((valid) => {
						if (valid) {
							this.$request.upLabelData({
								token,
								name: this.formValidate.name,
								img: this.logo,
								glid: this.id
							}).then(res => {
								if (res.code == 0) {
									this.$Message.success('添加成功!');
									this.$emit("del", 'ok');
									this.$refs['formValidate'].resetFields();
								}
							})
						} else {
							this.$Message.error('添加失败请输入名称！！！');
						}
					})
				}else{//添加
					this.$refs['formValidate'].validate((valid) => {
						if (valid) {
							this.$request.upLabelData({
								token,
								name: this.formValidate.name,
								img: this.logo,
								glid: this.id
							}).then(res => {
								if (res.code == 0) {
									this.$Message.success('添加成功!');
									this.$emit("del", 'ok');
									this.$refs['formValidate'].resetFields();
								}
							})
						} else {
							this.$Message.error('添加失败请输入名称！！！');
						}
					})
				}
				
			}
		}
	}
</script>

<style scoped="scoped">
	table {
		width: 100%;
		background: #eeeeee;
	}

	td {
		background-color: #FFFFFF;
		font-size: 15px;
		height: 30px;
		/* width: 15%; */
	}

	tr .titNa {
		background-color: #F9FAFC;
		font-size: 15px;
		font-weight: 800;
	}

	.oper {
		display: flex;
		justify-content: space-around;
		color: #42B983;
	}
	.img img{
		width: 20px;
	}
	.title {
		width: 88%;
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		height: 50px;
		line-height: 50px;
	}
	.add {
		height: 50px;
	}
	
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 58px;
		height: 58px;
		line-height: 58px;
		text-align: center;
	}
	
	.avatar {
		width: 58px;
		height: 58px;
		display: block;
	}
	
	.avatar-uploader-icon {
		border: 1px solid #333333;
	}
	
	.avatar-uploader {
		text-align: center;
	}
</style>
